@media (max-width: 421px) {
  .rotateText {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1183px) {
  .rotateText {
    display: none;
  }
}
